<app-navbar-one></app-navbar-one>
<!-- ภัยพิบัติ -->
<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>ภัยพิบัติ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>ภัยพิบัติ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="projects-section pt-70 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2><span>โครงการ</span> ถุงยังชีพเพื่อผู้ประสบภัย</h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/islael.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ประเทศอิสราเอล</h3>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-bo">
                    <div class="video-content">
                        <video controls loop src="assets/images/vdorbs.mp4" width="400" ></video>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/seria.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>สาธารณรัฐทูร์เคีย-ซีเรีย</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/nepal2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ประเทศเนปาล</h3>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/monk2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>วัดพระบาทน้ำพุ</h3>
                    </div>

                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/narathi2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>นราธิวาส</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/medic/russia2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>รัสเซีย-ยูเครน</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h4>ประเทศไทยจมบาดาล ปี 2554</h4>
            <p>เหตุการณ์มหาอุทกภัย</p>
            <h4>ประเทศเนปาล ปี 2558</h4>
            <p>เหตุแผ่นดินไหว</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/np-flood/iii.png" alt="image">
                    </div>


                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/np-flood/iii2.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/np-flood/iii3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/np-flood/iii4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section style="background-color: rgb(244, 252, 255);" class="projects-section pt-70 pb-70"> <!--โครงการ ไทยรวมใจ-->
    <div class="container-fluid">
        <div class="section-title">
            <h2>21 ก.พ. 66 โครงการ <span>“ไทยรวมใจ ช่วยภัยสาธารณรัฐทูร์เคีย-ชีเรีย 2023” </span></h2>
            <h4>ได้ทำกิจกรรมแพ็กถุงยังชีพจำนวน 3,000 ถุง</h4>
            <p>เพื่อส่งไปยังประเทศตุรกีและซีเรีย โดยมูลนิธินักศึกษาสถาบันพระปกเกล้าเพื่อสังคม<br> บริษัท รีเทล บิซิเนส
                โซลูชั่นส์ จำกัด (rbs)และเครือข่าย SEED Thailand 21 ก.พ. 66</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/seria/seria.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/seria/seria2.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/p2/seria-2.png" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-70 pb-70"> <!--โครงการ ช่วยเหลือพี่น้องประสบภัย-->
    <div class="container-fluid">
        <div class="section-title">
            <h2>8 ส.ค. 66 <span>ช่วยเหลือพี่น้องผู้ประสบภัย
                จากกรณีโกดังเก็บพลุ ดอกไม้ไฟระเบิด</span></h2>

            <p>จากกรณีโกดังเก็บพลุดอกไม้ไฟระเบิด มอบถุงยังชีพจำนวน 200 ชุด ให้กับพี่น้องชาวมูโนะ <br>อ.สุไหงโก-ลก
                จ.นราธิวาส</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/firework/fw1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/firework/fw2-2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/firework/fw4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/firework/fw3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pb-70"> <!--โครงการ ส่งน้ำใจให้คนไทยในอิสราเอล-->
    <div class="container-fluid">
        <div class="section-title">
            <h2>19 ต.ค. 66 ได้ส่งมอบ <span>"ถุงน้ำใจ"</span>จำนวน 500 ชุด</h2>
            <h3>จากโครงการ <span>"ส่งน้ำใจให้คนไทยในอิสราเอล"</span></h3>

            <p>ซึ่งเป็นโครงการที่เดิกจากความร่วมมือของ rbs group มูลนิธินักศึกษาสถาบันพระปกเกล้าเพื่อสังคม กรมการกงสุล
                กระทรวงต่างประเทศ และ บริษัท การบินไทย จำกัด (มหาชน)</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/israel/is1.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/israel/is2.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/israel/is3.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/israel/is4.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/israel/is5.png" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-100 pb-70"> <!--สงครามรัสเซีย-->
    <div class="container-fluid">
        <div class="section-title">
            <h2> สถานการณ์<span>สงครามรัสเซีย-ยูเครน</span>ปี 2566</h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/war/war1.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/war/war2.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/war/war4.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/war/war3.png" alt="image">
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-100 pb-70"> <!--โรงเรียนเชียงใหม่-->
    <div class="container-fluid">
        <div class="section-title">
            <h3> โรงเรียนสหมิตรวิทยา จ.เชียงใหม่ ศศช.บ้านคุหะ จ.ตาก วัดสุพรรณรังษี จ.แม่ฮ่องสอน 165 รูป วัดพระบาทน้ำพุ</h3>
            <h6>22 มิ.ย. 2566</h6>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bx">
                    <div class="projects-image">
                        <img src="assets/images/cnx/cm1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-bo">
                    <div class="projects-image">
                        <img src="assets/images/cnx/cm2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/cnx/cm4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="single-projects-ox">
                    <div class="projects-image">
                        <img src="assets/images/cnx/cm3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
