<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/images/rbsgray.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">หน้าหลัก </a>
                    </li>
                    <li class="nav-item"><a routerLink="/education" class="nav-link">ความรู้การศึกษา </a>
                    </li>
                    <li class="nav-item"><a routerLink="/medical" class="nav-link">การแพทย์ </a>
                    </li>
                    <li class="nav-item"><a routerLink="/disaster" class="nav-link">ภัยพิบัติ </a>
                    </li>
                    <li class="nav-item"><a routerLink="/social" class="nav-link">สังคม </a>
                    </li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ติดต่อ</a></li>
                </ul>

            </div>
        </nav>
    </div>
</div>
