<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>ติดต่อ</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <a href='https://www.google.com/maps/place/บริษัท+รีเทล+บิซิเนส+โซลูชั่นส์+จำกัด+(rbs)/@13.8318949,100.6238946,19z/data=!4m6!3m5!1s0x311d6283d0da7d39:0xac62a23928afe7ce!8m2!3d13.8322057!4d100.6243127!16s%2Fg%2F1tfzpclv?authuser=0&entry=ttu'>บริษัท รีเทล บิซิเนส โซลูชั่นส์ จำกัด (rbs)
                            387 ถ.สุคนธสวัสดิ์ แขวงลาดพร้าว
                            เขตลาดพร้าว กรุงเทพมหานคร 10230</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:sca&#64;rbs-groups.com">sca&#64;rbs-groups.com</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:+665530077">(66+) 553 0077</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

