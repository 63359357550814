<app-navbar-one></app-navbar-one>
<!-- สังคม -->
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>สังคม</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="creative-section pt-100 pb-0 pb-md-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>โครงการ <span>คืนคนดีสู่สังคม</span></h3>
                    <div class="bar"></div>
                    <p>เพราะทุกชีวิตย่อมผิดพลาด <br>การ<span> "คืนคนดีสู่สังคม" </span>ได้มอบโอกาสครั้งที่ 2</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="vdo-soc">
                    <video controls loop src="assets/images/p2/vdo/bf4b219bae9204003fa8fb3217b1d3f2.mp4"
                        width="100.25%"></video>
                </div>

            </div>
        </div>
    </div>

    <div class="partner-section ptb-0 ptb-md-50">
        <div class="custom-container">
            <div class="partner-logo2">
                <div class="partner-item">
                    <img src="assets/images/rbsgray.png" alt="partner">
                </div>
                <div class="partner-item">
                    <img src="assets/images/NiceCall.png" alt="partner">
                </div>
                <div class="partner-item">
                    <img src="assets/images/sps.png" alt="partner">
                </div>
                <div class="partner-item">
                    <img src="assets/images/correction.png" alt="partner">
                </div>
                <div class="partner-item">
                    <img src="assets/images/tijj.png" alt="partner">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h2 style="text-transform: lowercase;">rbs ร่วมกับ กรมราชทัณฑ์ ตกลงความร่วมมือในโครงการ
                        <span
                            style="color: rgb(44, 136, 255);">“คืนคนดีสู่สังคม” </span>ส่งเสริมการฝึกอาชีพให้ผู้ต้องขังชาย
                        โดยนำผู้ต้องขังจากเรือนจำกลางสมุทรปราการมาทำงานในโรงงาน
                        ตั้งแต่ ปี 2561 มีผู้ต้องขังการฝึกอาชีพแล้วมากกว่า 110 คน
                    </h2>
                    <div class="features-text">
                        <h4> วัตถุประสงค์</h4>
                        <ul class="list" style="list-style-type: none;">
                            <li><i class="flaticon-happy"></i> ดำรงชีวิตอยู่ได้อย่างมีความรู้ ความสามารถ
                                และนำไปใช้ประกอบอาชีพ ได้อย่างสุจริตและยั่งยืน</li><br>
                            <li><i class="flaticon-happy"></i>
                                ต้องการให้ผู้ต้องขังใช้เวลาในขณะที่ถูกคุมขังอย่างเกิดประโยชน์</li><br>
                            <li><i class="flaticon-happy"></i> ผู้ต้องขังได้ฝึกทักษะเพื่อนำมาใช้หลังจากพ้นโทษ</li><br>
                            <li><i class="flaticon-happy"></i> ผู้ต้องขังมีชีวิตความเป็นอยู่ที่ดีขึ้น</li><br>
                            <li><i class="flaticon-happy"></i> ผู้ต้องขังมีเงินเก็บเพื่อนำมาใช้หลังจากพ้นโทษ</li><br>
                            <li><i class="flaticon-happy"></i>
                                ลดการกระทำผิดซ้ำอีกเมื่อพ้นโทษไปแล้วเนื่องจากมีอาชีพสุจริต
                            </li><br>
                            <li><i class="flaticon-happy"></i> เลี้ยงชีวิตได้</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-50 pt-lg-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>ทีมสมุทรปราการ</h2>
            <p>ฝึกวิชาชีพและส่งเสริมทักาะการทำงานให้แก่ผู้ต้องขังประเภทการผลิตชิ้นงาน<br><i class="flaticon-happy"></i> ทักษะการปั้มชิ้นงาน<br><i class="flaticon-happy"></i> ทักษะการเชื่อมชิ้นงาน<br><i class="flaticon-happy"></i> ทักษะการเจียร์ชิ้นงาน<br><i class="flaticon-happy"></i> การตรวจสอบชิ้นงาน
            </p>
            <div class="bar"></div>
        </div>
        <div class="custom-row-4-col row-tablet">
            <div class="">
                <div class="custom-projects-box ">
                    <div  class="projects-image">
                        <img src="assets/images/samut/smpk2-2.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>ทักษะการปั้มชิ้นงาน</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/samut/smpk4.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>ทักษะการเชื่อมชิ้นงาน</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/samut/smpk3.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>ทักษะการเจียร์ชิ้นงาน</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/samut/smpk.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>การตรวจสอบชิ้นงาน</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/images/women/wm4.png" alt="image">

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>ฝึกวิชาชีพและส่งเสริมทักษะ​การทำงานให้แก่ผู้ต้องขังประเภทการขายทางโทรศัพท์</h2>
                    <div class="bar"></div>
                    <h3 style="color: rgb(48, 159, 255);">ผู้ต้องขังหญิง</h3>
                    <div class="tab about-list-tab">

                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h5>ปัจจุบันให้เรือนจำกลางสมุทรปราการเป็นต้นแบบและมีแผนขยายไปยังเรือนจำ
                                        ชลบุรีและเชียงใหม่</h5>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-happy"></i> ปัจจุบันมีทั้งหมด 76 ที่นั่ง (เฟส1+2)</li>
                                    <li><i class="flaticon-happy"></i> มีผู้ต้องขังทำงานอยู่ 44 คน</li>
                                    <li><i class="flaticon-happy"></i> ระยะเวลาที่ดำเนินการมาแล้ว 4 ปี</li>
                                    <li><i class="flaticon-happy"></i> จำนวนผู้ต้องขังที่ได้เข้ามาทำงานแล้วมากกว่า 160
                                        คน</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="custom-projects-box-soc">
                    <div class="projects-image">
                        <img src="assets/images/women/wm1.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="custom-projects-box-soc">
                    <div class="projects-image">
                        <img src="assets/images/women/wm2.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="custom-projects-box-soc">
                    <div class="projects-image">
                        <img src="assets/images/women/wm3.png" alt="img">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-100 pb-70"> <!--บริจาค-->
    <div class="container">
        <div class="section-title">
            <div class="partner-section ptb-50">
                <div class="container">
                    <div class="partner-logo3">
                        <div style="width: 20%;" class="partner-item">
                            <img src="assets/images/rbsgray.png" alt="partner">
                        </div>
                    </div>
                </div>
            </div>
            <h2>การบริจาคช่วยเหลือสังคม</h2>
            <p>เป็นกิจกรรมที่มีความหมาย
                อันสำคัญในการส่งเสริมความ
                เข้าใจและความเอาใจใส่ต่อผู้อื่น
                ในสังคม ช่วยสนับสนุนความ
                รับผิดชอบต่อสังคมและสร้าง
                สุขภาพจิตใจที่ดีให้กับผู้เข้าร่วม
                กิจกรรมด้วย</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/soc/soc1.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>บริจาคน้ำดื่ม</h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/soc/soc2.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>บริจาคโลหิต</h3>


                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 ">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/soc/soc3.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>บริจาคอุปกรณ์คอมพิวเตอร์</h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 ">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/soc/soc4.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>บริจาคเตียงทันตกรรม</h3>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-100 pb-70"> <!--water-->
    <div class="container">
        <div class="section-title">
            <h2>บริจาคน้ำดื่ม</h2>
            <p>ให้กับทางโรงพยาบาล หน่วยงานแพทย์
                หน่วยงานเอกชน และหน่วยงานราชการ
                <br>ในโอกาสต่างๆ  มากกว่า 200,000 ขวด</p>
            <div class="bar"></div>
        </div>
        <div class="custom-row-3-col">
            <div class="">
                <div class="custom-projects-box-water tablet">
                    <div class="projects-image">
                        <img src="assets/images/water/water1.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-water tablet">
                    <div class="projects-image">
                        <img src="assets/images/water/water2.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-water tablet">
                    <div class="projects-image">
                        <img src="assets/images/water/water3.png" alt="img">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-100 pb-70"> <!--สนับสนุนเตียง-->
    <div class="container-fluid">
        <div class="section-title">
            <h2><span>สนับสนุน</span>เตียงทันตกรรมเคลื่อนที่สำหรับงานภาคสนาม</h2>
            <h3><span>สำหรับงานภาคสนาม</span></h3>

            <p>คณะทันตแพทยศาสตร์ จุฬาฯ 10 เตียง <br>คณะแพทย์ศาสตร์ รพ.รามาธิบดี 20เตียง <br>ที่ประเทศเนปาล 6เตียง</p>
            <div class="bar"></div>
        </div>
        <div class="custom-row-col-5">
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/dent/bedm2.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/dent/bedm1.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/dent/bedm3.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/dent/bedm4.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/dent/bedm5.png" alt="img">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-70 pb-70"> <!--คอม-->
    <div class="container-fluid">
        <div class="section-title">
            <h2>บริจาคอุปกรณ์คอมพิวเตอร์</h2>
            <p>สนับสนุนเครื่องคอมพิวเตอร์
                และอุปกรณ์เก่าที่ยังมีสภาพใช้งานได้
                ให้กับทางโรงเรียนที่ขาดแคลน
                500 เครื่อง
            </p>
            <div class="bar"></div>
        </div>
        <div class="row row-tablet-2">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/com/com1.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/com/com4.png" alt="image">
                    </div>

                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/com/com3.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/com/com2.png" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="projects-section pt-100 pb-70"> <!--โลหิต-->
    <div class="container-fluid">
        <div class="section-title">
            <h2>บริจาคโลหิต</h2>
            <p>พนักงาน rbs ร่วมบริจาคโลหิต
                ที่คณะแพทยศาสตร์ โรงพยาบาลรามาธิบดี
                มหาวิทยาลัยมหิดล เนื่องจากโลหิตขาดแคลน
                ช่วงการแพร่ระบาดของ COVID-19
                (20 กุมภาพันธ์ 2564)</p>
            <div class="bar"></div>
        </div>
        <div class="row row-tablet-2">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/blood/blood1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/blood/blood3.png" alt="image">
                    </div>

                </div>
                <div class="custom-projects-box-blood">
                    <div class="projects-image">
                        <img src="assets/images/blood/blood4.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/blood/blood2with.png" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-70 pb-100">
    <div class="container">
        <div class="section-title">
            <h2>โครงการ ส่งเสริมและสนับสนุนการเรียนรู้ภายในองค์กร</h2>
            <p>สนับสนุนการเรียน แก่เด็กที่เป็นบุตรของพนักงาน
                ที่มีผลการเรียนดี ตลอดจนส่งเสริมพนักงานที่ต้อง
                การศึกษาหาความรู้เพิ่มเติมในระดับที่สูงขึ้น สร้าง
                บัณฑิตใหม่ ในหลายรุ่น มากกว่า 20 คน</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/support/sp1.png" alt="img">
                    </div>
                    <div class="content">
                        <h3>มอบทุนส่งเสริมการเรียนและศึกษาต่อ</h3>
                        <span>ในระดับป.ตรีและป.โท (ปัจจุบันรุ่นที่ 6)
                            จบเป็นบัณฑิตใหม่ จำนวน 25 ท่าน</span>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/support/sp2.png" alt="img">
                    </div>
                    <div class="content">
                        <h3>มอบทุนส่งเสริมบุตรเรียนดี</h3>
                        <span>สนับสนุนการศึกษาบุตร ให้พนักงานที่มีบุตรเรียนดี
                            ทุนละ 5,000-10,000 บาท ระยะเวลากว่า 12 ปี</span>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/support/sp3.png" alt="img">
                    </div>
                    <div class="content">
                        <h3>มอบทุนด้านกีฬา</h3>
                        <span>สนับสนุนด้านอุปกรณ์และการแข่งขัน</span>

                    </div>
                </div>
            </div>
        </div>
        <div class="hack"></div>
    </div>
</section>
