<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>ความรู้การศึกษา</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- โครงการสนับสนุนเงินให้กับโรงเรียน -->
<section class="tab-section pb-100 pt-70">
    <div class="container">
        <div class="section-title">
            <h2>ส่งมอบ <span>สิ่งดีๆ</span> ให้เยาวชน</h2>
            <h5>โครงการ สนับสนุนเงินให้กับโรงเรียน </h5>
            <p>อาหารกลางวัน,จ้างครู,ทุนการศึกษา,อุปกรณ์การเรียน <br>ตั้งแต่ปี 2550 - ปัจจุบัน</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span> ตั้งแต่ปี 2550 - ปัจจุบัน</span></a></li>
                <li><a href="#"><span> อาหารกลางวัน | จ้างครู | ทุนการศึกษา | อุปกรณ์การเรียน</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>โครงการ สนับสนุนเงินให้กับโรงเรียน</h2>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ตั้งแต่ปี 2550 - ปัจจุบัน</h3>
                                    <p>มอบทุนช่วยเหลือการศึกษา อุปกรณ์การเรียน
                                        ให้กับนักเรียนที่ขาดแคลน และภาวะทุพโภชนาการ
                                        ให้ได้รับอาหารกลางวันที่ดี มีคุณภาพและถูกหลัก​โภชนาการ
                                        นักเรียนมีสุขภาพกายที่แข็งแรง สมบูรณ์
                                        มีน้ำหนักส่วนสูง ตามเกณฑ์มาตรฐานของกรมอนามัย ​กระทรวงสาธารณสุข
                                        ส่งเสริมการจ้างครูที่มีศักยภาพ​เพียงพอ ช่วยเสริมสร้างความรู้ให้กับนักเรียน</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="custom-tab-image">
                                <img src="assets/images/food.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>โครงการ สนับสนุนเงินให้กับโรงเรียน</h2>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>อาหารกลางวัน | จ้างครู | ทุนการศึกษา | อุปกรณ์การเรียน</h3>
                                    <ol>
                                        <p><li>วัดตาลเจ็ดช่อ จ.อ่างทอง</li></p>
                                        <p><li>โรงเรียนสิทธิสุนทรอุทิศ จ.ฉะเชิงเทรา</li></p>
                                        <p><li>โรงเรียนชุมชนบ้านอุ้มผาง (บ้านยะโมคิ) จ.ตาก</li></p>
                                        <p><li>โรงเรียนบ้านหนองมะกอก จ.เพชรบุรี</li></p>
                                        <p><li>โรงเรียนบ้านบึงกระโพธิ์ จ.ศรีสะเกษ</li></p>
                                        <p><li>โรงเรียนบ้านแขขมโพนทอง จ.ศรีสะเกษ</li></p>
                                    </ol>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/images/food2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ปันหนังสือให้น้องอ่าน(ปลูกคนมาสร้างชาติ) -->
<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2><span>โครงการ</span> ปันหนังสือให้น้องอ่าน</h2>
            <h4>“ปลูกคน” มาสร้างชาติ</h4>
            <p>จากวิกฤตด้านการศึกษาของเยาวชนไทยตาม​ถิ่นทุรกันดาร อาทิ วัด ชุมชน โรงเรียน <br>ที่ขาดการ​เหลียวแล
                ส่งผลให้ประเทศกำลังเผชิญกับปัญหา​การพัฒนาที่ขาดสมดุล <br> <br>ความรับผิดชอบต่อสังคมขององค์กรหรือ ​CSR
                ที่มีต่อเยาวชนไทย
                จึงเป็นเรื่องสำคัญและ​จำเป็นที่ต้องร่วมกันสร้างบรรทัดฐานเพื่อให้ธุรกิจ​และสังคมสามารถพัฒนาเติบโตไปด้วยกันได้อย่าง​ยั่งยืน<br>**ห้องสมุดแห่งแรกชุมชนรังสิต
            </p>
            <div class="bar"></div>
        </div>
        <div class="custom-row-3-col">
            <div class="">
                <div class="custom-projects-box-punjai">
                    <div class="projects-image">
                        <img src="assets/images/p2/pjbook.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punjai">
                    <div class="projects-image">
                        <img src="assets/images/book1.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punjai">
                    <div class="projects-image">
                        <img src="assets/images/p2/book.png" alt="img">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- ปันหนังสือให้น้องอ่าน -->
<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2><span>โครงการ </span>ปันหนังสือให้น้องอ่าน</h2>
            <div class="bar"></div>
        </div>
        <div class="custom-row ">
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book2-2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดชั้นวางหนังสือในห้องสมุด</h3>

                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book3.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>หนังสือที่ได้รับการบริจาค</h3>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book4.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดโต๊ะ + เก้าอี้ อ่านหนังสือ</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book5.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดโต๊ะ + เก้าอี้ นักเรียน</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ปันหนังสือให้น้องอ่าน -->
<section class="projects-section pt-70 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2> <span>โครงการ</span> ปันหนังสือให้น้องอ่าน</h2>
            <div class="bar"></div>
        </div>
        <div class="custom-row-col-3">
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book6-2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ติดต่อไปยังกลุ่มเป้าหมาย วัด ชุมชน โรงเรียน ขอข้อมูลพื้นที่</h3>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book7.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ออกแบบการจัดวาง การใช้พื้นที่ เพื่อประโยชน์การใช้งานอย่างมีประสิทธิภาพ</h3>

                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book9.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ประสานงาน</h3>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book11.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>พร้อมส่งมอบ และติดตามผล</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book10.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>และ​หนังสือ นัดหมายวันเข้าติดตั้ง</h3>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-punbook">
                    <div class="projects-image">
                        <img src="assets/images/p2/book8.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>จัดเตรียมอุปกรณ์</h3>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 426โรงเรียน ทั่วประเทศไทย77จังหวัด -->
<section class="about-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div style="display: flex; align-items: center; justify-content: center;" class="thai">
                    <img src="https://rbssca.my.canva.site/images/609b7fd307e60857ab07ca4ec652f943.svg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>ปัจจุบัน 426 โรงเรียน</h2>
                    <div class="bar"></div>
                    <div class="tab about-list-tab">
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>ทั่วประเทศไทย 77 จังหวัด</h3>
                                </div>
                                <ul class="list">
                                    <li>2567 | ครบ 500 โรงเรียนในปี 67</li>
                                    <li>2560 | ปันหนังสือให้น้องอ่าน rbs
                                        ร่วมกับสำนักงานรองนายกรัฐมนตรีและกองบัญชาการตำรวจตระเวนชายแดน</li>
                                    <li>2558 | ปันหนังสือให้น้องอ่าน rbs ร่วมกับสภานิติบัญญัติแห่งชาติ</li>
                                    <li>2557 | rbs เริ่มต้น “ปลูกคนมาสร้างชาติ”ผ่านโครงการแบ่งปันความรู้สู่ชุมชน</li>
                                    <li>2554 | ห้องสมุดแห่งแรก ชุมชนรังสิต</li>
                                </ul>
                                <a class="default-btn" routerLink="/projects-details">ดูโรงเรียนทั้ง 426 โรงเรียน</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- โครงการ สหกรณ์ปันน้ำใจ -->
<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2><span>โครงการ</span> สหกรณ์ปันน้ำใจ</h2>
            <p>“ประสบการณ์ด้านธรุกิจค้าปลีก
                มากกว่า 30 ปี” <br>บริษัทฯ มีแนวคิดสร้างร้านค้าปลีก
                ในโรงเรียน โดยให้นักเรียน เรียนรู้
                ฝึกฝนการบริการจัดการร้านค้าปลีก
                นำไปต่อยอดเป็นอาชีพ สร้างรายได้
                ให้กับโรงเรียนและชุมชน <br>ตั้งแต่ปี 2565 - ปัจจุบัน
            </p>
            <div class="bar"></div>
        </div>
        <div class="custom-row-col-3">
            <div class="custom-projects-box">
                <div class="projects-image">
                    <img src="assets/images/punjaii/punjai.png" alt="image">
                </div>
                <div class="projects-content">
                    <h3>ขนาดพื้นที่(Type)</h3>
                    <span> A มีขนาดพื้นที่ไม่เกิน 12 ตรม. <br>B มีขนาดพื้นที่ไม่เกิน 20 ตรม.</span>
                </div>

            </div>
            <div class="custom-projects-box">
                <div class="projects-image">
                    <img src="assets/images/punjai/pj.png" alt="image" >
                </div>
                <div class="projects-content">
                    <h3>ส่งมอบ อบรมการใช้งาน นักเรียนลงมือปฏิบัติจริง ติดตามผล</h3>

                </div>

            </div>
            <div class="custom-projects-box">
                <div class="projects-image">
                    <img src="assets/images/punjaii/punjai3.png" alt="image">
                </div>
                <div class="projects-content">
                    <h3>ภายใต้เงื่อนไข</h3>
                    <span>โรงเรียนต้องจัดพื้นที่สำหรับร้านค้าปลีกตามขนาด Type A, B
                        <br>ร้านค้าต้องอยู่ในจุดที่มองเห็นเด่นชัด เข้าถึงง่าย
                        <br>ทางโรงเรียนจะต้องมีสินค้าที่ผลิตเองจากชุมชน นำมาจำหน่าย มีระบบไฟฟ้ารองรับ เครื่องคิดเงิน
                        พัดลม ฯลฯ <br>โรงเรียนต้องจัดหาครู/นักเรียน รับผิดชอบโครงการ <br>โรงเรียนต้องจัดทำรายงาน
                        ยอดขาย/กำไรขาดทุน ส่งบริษัท เป็นรายเดือน</span>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- โครงการ สหกรณ์ปันน้ำใจ #ครั้งที่1-->
<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>โครงการ สหกรณ์ปันน้ำใจ <span>#ครั้งที่1</span></h2>
            <h5>โรงเรียนบ้านคลองยาง</h5>
            <h6>สนับสนุน สหกรณ์โรงเรียน 1 ร้าน</h6>
            <p>1.ชั้นวางสินค้า
                2.เคาน์เตอร์ชำระเงิน
                3.เครื่องคิดเงิน
                4.สินค้าสำหรับจำหน่าย</p>
            <br>
            <h6>ใช้ระยะเวลาทั้งสิ้น 42 วัน</h6>
            <div class="bar"></div>
        </div>
        <div class="custom-row">
            <div class="">
                <div class="single-projects-b">
                    <div class="projects-image">
                        <img src="assets/images/punjai/punjai-1-no1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="single-projects-b">
                    <div class="projects-image">
                        <img src="assets/images/punjai/punjai-2-no1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="single-projects-b">
                    <div class="projects-image">
                        <img src="assets/images/punjai/punjia-3-no1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="single-projects-b">
                    <div class="projects-image">
                        <img src="assets/images/punjai/punjai-4-no1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- โครงการ สหกรณ์ปันน้ำใจ #ครั้งที่2-->
<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>โครงการ สหกรณ์ปันน้ำใจ <span>#ครั้งที่2</span></h2>
            <h3>โรงเรียนบ้านตาลเจ็ดยอด</h3>
            <h6>สนับสนุน อุปกรณ์</h6>
            <p>1.สหกรณ์โรงเรียน 1 ร้าน | 2.โต๊ะ+เก้าอี้ โรงอาหาร 20 ชุด | 3.เตียง ห้องพยาบาล 4 ชุด <br> 4.โต๊ะ+เก้าอี้
                นักเรียน ชั้นประถม 25 ชุด | 5.โต๊ะนักเรียน ชั้นอนุบาล
            </p><br>
            <div class="bar"></div>
        </div>
        <div class="custom-row-col-5">
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/p2/punjai-1-no2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/p2/punjai-2-no2.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/p2/punjai-3-no2.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/p2/punjai-4-no2.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-sp">
                    <div class="projects-image">
                        <img src="assets/images/p2/punjai-5-no2.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
