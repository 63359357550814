<app-navbar-one></app-navbar-one>
<!-- home -->
<div class="main-banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>SOCIAL CONTRIBUTION ACTIVITIES</h1>
                            <p>กิจกรรมช่วยเหลือสังคม โดยไม่หวังสิ่งตอบแทน</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="optional-btn">ติดต่อเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/images/rbsimg.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="partner-section ptb-50">
                <div class="container">
                    <div class="partner-logo">
                        <div class="partner-item">
                            <img src="assets/images/krut.png" alt="partner">
                        </div>
                        <div class="partner-item">
                            <img src="assets/images/Alpaca.png" alt="partner">
                        </div>
                        <div class="partner-item">
                            <img src="assets/images/thx.png" alt="partner">
                        </div>
                        <div style="width: 110%" class="partner-item">
                            <img src="assets/images/correction.png" alt="partner">
                        </div>
                        <div style="width: 150%;" class="partner-item">
                            <img src="assets/images/affair.png" alt="partner">
                        </div>
                        <div class="partner-item">
                            <img src="assets/images/police.png" alt="partner">
                        </div>
                        <div  class="partner-item">
                            <img src="assets/images/2.png" alt="partner">
                        </div>
                        <div class="partner-item">
                            <img src="assets/images/NiceCall.png" alt="partner">
                        </div>
                        <div class="partner-item">
                            <img src="assets/images/seed.png" alt="partner">
                        </div>
                        <div style="width: 110%;" class="partner-item">
                            <img src="assets/images/tijj.png" alt="partner">
                        </div>
                        <div style="justify-content: center;" class="partner-item">
                            <img src="assets/images/rbsgray.png" alt="partner">
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="banner-shape"><img src="assets/img/home-two/cloud.png" alt="image"></div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<!-- โครงการต่างๆๆ -->
<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-box">
                    <div class="projects-image">
                        <img src="assets/images/p2/p1" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>โครงการ สนับสนุนเงินให้กับโรงเรียน</h3>
                    </div>
                    <a routerLink="/services-one" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-box">
                    <div class="projects-image">
                        <img src="assets/images/p2/pp2" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>โครงการ ปันหนังสือให้น้องอ่าน</h3>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-box">
                    <div class="projects-image">
                        <img src="assets/images/p2/pp3" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>โครงการ Save Thai Fight Covid</h3>
                    </div>
                    <a routerLink="/projects-one" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-box">
                    <div class="projects-image">
                        <img src="assets/images/p2/pp4 " alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>โครงการ “ไทยรวมใจ ช่วยภัยสาธารณรัฐทูร์เคีย-ชีเรีย 2023”</h3>
                    </div>
                    <a routerLink="/services-two" class="link-btn"></a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- CSR&SCA -->
<section class="clients-section">
    <div class="container">
        <div style="padding-top: 50px; padding-bottom: 50px;" class="section-title">
            <h2>CSR <span>&</span> SCA</h2>
            <p>Corporate Social Resoinsibility and Social Contribution Activity</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>CSR เป็นแนวทางการดำเนินธุรกิจที่ครอบคลุมซึ่งผสมผสานความรับผิดชอบต่อสังคม
                    และสิ่งแวดล้อมเข้า​ไว้ในการดำเนินงาน ทุกด้านของบริษัท ในทางกลับกัน SCA เป็นสิ่งทุกคนสามารถทำกิจกรรม
                    เพื่อสังคมได้ ​ในโครงการต่าง ๆ โดยไม่จำเป็นต้องเกี่ยวข้องกับดำเนินธุรกิจ</p>
                <div class="clients-content">
                    <h3>CSR & SCA</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p> โดยทั่วไป CSR จะถูกรวมเข้ากับการวางแผนเชิงกลยุทธ์และการปฏิบัติการ​ของบริษัท
                    ซึ่งสะท้อนถึงความมุ่งมั่นในการพัฒนาที่ยั่งยืนในระยะยาว แต่ SCA เป็นกิจกรรมการมีส่วน​ร่วมทางสังคม
                    อาจไม่ได้รวมเข้ากับกลยุทธ์ระยะยาวขององค์กรเสมอไป และอาจเป็นแบบเฉพาะกิจหรือ​ตามโครงการก็ได้</p>
                <div class="clients-content">
                    <h3>การบูรณาการเชิงกลยุทธ์</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>แม้ว่า CSR จะเกี่ยวข้องกับผลกระทบของการดำเนินงานของบริษัทต่อผู้มีส่วนได้ส่วน​เสียที่หลากหลาย
                    (รวมถึงพนักงาน ลูกค้า ซัพพลายเออร์ และชุมชนในวงกว้าง) ในทางกลับกัน SCA
                    เป็น​กิจกรรมการมีส่วนร่วมทางสังคม อาจมุ่งเน้นที่ความต้องการหรือความคิดริเริ่มเฉพาะของชุมชน
                    หรือตาม​สนใจ</p>
                <div class="clients-content">
                    <h3>ผู้มีส่วนได้ส่วนเสีย</h3>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- Def&scope -->
<section class="services-section bg-background pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <h3>Definition</h3>
                    <p>ความรับผิดชอบทางสังคมเชิงบรรษัท (บรรษัทบริบาล) หมายถึง <br>“รูปแบบธุรกิจ ที่บริษัทต่างๆ
                        ผสมผสานความรับผิด​ชอบทางสังคมและสิ่งแวดล้อมในการดำเนินธุรกิจและ​การมีปฏิสัมพันธ์
                        กับผู้มีส่วนได้ส่วนเสีย” <br><br><br><br> </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <h3>Scope</h3>
                    <p>CSR มีความเฉพาะเจาะจงสำหรับองค์กรธุรกิจ
                        และมุ่งเน้นไปที่​วิธีที่องค์กรเหล่านี้จัดการกับผลกระทบด้านจริยธรรม สังคม ​และสิ่งแวดล้อม
                        โดยเป็นส่วนหนึ่งของแนวทางปฏิบัติทาง​ธุรกิจ
                        โดยครอบคลุมกิจกรรมต่างๆ มากมาย รวมถึงหลักปฏิบัติด้าน​แรงงานที่มีจริยธรรมการอนุรักษ์สิ่งแวดล้อม
                        การมีส่วนร่วมของชุมชน และการดำเนินธุรกิจอย่างยั่งยืน</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <h3>Objective</h3>
                    <p>เป้าหมายของ CSR นั้นกว้างกว่ากิจกรรมเพื่อสังคมส่วนบุคคล
                        ​โดยมีจุดมุ่งหมายเพื่อให้บริษัทดำเนินธุรกิจในลักษณะที่ส่งเสริม​สังคมและสิ่งแวดล้อม
                        แทนที่จะสร้างผลเสียต่อผู้มีส่วนได้ส่วน​เสีย ซึ่งอาจรวมถึงกลยุทธ์ระยะยาวของธุรกิจ เช่น
                        เพื่อปรับปรุงการศึกษา และรับรองห่วงโซ่อุปทานที่มีจริยธรรม​โดยมีจุดประสงค์ที่จะบูรณาการ
                        แนวทางปฏิบัติเหล่านี้เข้ากับ​กลยุทธ์ธุรกิจหลัก</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <h3>Definition</h3>
                    <p>กิจกรรมการมีส่วนร่วมทางสังคม<br> หมายถึง <br>“การดำเนินการโดยสมัครใจของบุคคล
                        หรือองค์กร เพื่อสนับสนุน
                        และปรับปรุงชุมชนและสิ่งแวดล้อม“<br><br><br></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <h3>Scope</h3>
                    <p>กิจกรรมเหล่านี้สามารถดำเนินการได้ไม่ว่าจะเป็นบุคคล กลุ่ม ​องค์กรที่ไม่แสวงหาผลกำไร หรือธุรกิจ
                        โดยไม่คำนึงถึงโครงสร้างองค์กร จุดมุ่งเน้นอยู่ที่การดำเนิน​การและผลกระทบโดยตรงต่อสวัสดิการสังคม
                        ความยั่งยืน​ด้านสิ่งแวดล้อม หรือการพัฒนาชุมชน <br><br></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">

                    <h3>Objective</h3>
                    <p>เป้าหมายหลักคือการให้การสนับสนุนโดยตรงต่อสังคม สิ่ง​แวดล้อม หรือชุมชน กิจกรรมต่างๆ
                        มักเป็นไปตามโครงการ​หรือขับเคลื่อนด้วยกิจกรรม โดยคำนึงถึงวัตถุประสงค์และ​ผลลัพธ์ที่เฉพาะเจาะจง
                        เช่น การสร้างศูนย์ชุมชน การจัด​กิจกรรมทำความสะอาด หรือการมอบทรัพยากรทางการศึกษา​แก่เด็กด้อยโอกาส
                    </p>

                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<!-- SCA-Timeline -->
<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <div class="container">
                <div style="display:flex; justify-content: center; align-items: center;" class="partner-logo">
                    <div style="width: 7%;" class="partner-item">
                        <img src="assets/images/rbsgray.png" alt="partner">
                    </div>
                    </div>
                </div>
            <h5><span>SCA</span> (Social Contribution Activity) <br>Timeline</h5>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/p2/p1" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>April 2007 - Present</h3>
                        <span>บริจาคทุนการศึกษา อาหารกลางวัน​และกองทุนพัฒนาคุณภาพทางการ​ศึกษา จำนวน 6 โรงเรียน</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div  class="projects-image">
                        <img src="assets/images/june2014.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>June 2014 - Present</h3>
                        <span>ปันหนังสือให้น้องอ่าน ตั้งแต่ปี 2014 ถึงปัจจุบัน 426 โรงเรียน ​เพื่อเสริมสร้างและพัฒนาโอกาสใน​การเรียนรู้ทั้งในโรงเรียน วัดและ​ชุมชนในจังหวัดต่าง ๆ</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div  class="projects-image">
                        <img src="assets/images/p2/pp4" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>2011,2015 - Present</h3>
                        <span>ช่วยเหลือทุกวิกฤติการณ์ของโลก เหตุการณ์น้ำท่วม เหตุการณ์แผ่นดินไหว ภาวะสงคราม มอบ“ถุงยังชีพ” 4,000 ถุง</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/sep2018.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>Sep 2018 - Present</h3>
                        <span>โครงการคืนคนดีสู่สังคม วัตถุประสงค์ของโครงการ คือ การฝึก​อบรมงานให้กับผู้ต้องขังชาย จากเรือน​จำกลางสมุทรปราการ ปัจจุบันมีผู้ต้อง​ขังเข้าร่วมโครงการกว่า 100 คน</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/graduated.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>Jan 2020 - Present</h3>
                        <span>สนับสนุนด้านทุนการศึกษาและทุนด้านกีฬา</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/april202.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>April 2020 - Present</h3>
                        <span>โครงการ SOS COVID-19 Fight ช่วยเหลือ​บุคลากรทางการแพทย์ จากการวิจัยของเรา Covid 19 Fight ออกแบบและผลิตตู้ฆ่าเชื้อ UVC กล่องใส บริจาคให้โรงพยาบาลมากกว่า 587 แห่ง</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/save-thai-fight.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>July 2020 - Present</h3>
                        <span>จัดทำกล่อง Save Thai Fight Covid เพื่อบริจาคจำนวนทั้งสิ้น 1,650 กล่อง</span>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="home2-1-box">
                    <div class="projects-image">
                        <img src="assets/images/aug2020.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>Aug 2020 - April 2022</h3>
                        <span>โครงการศูนย์พักคอยเพื่อส่งต่อเขตลาดพร้าว เปิดวันที่ 12 สิงหาคม 2564 จำนวนเตียง 175 เตียง รวมจำนวนผู้ป่วยที่ได้เข้ารับ การรักษาแล้วทั้งสิ้นกว่า 1,000 คน</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

