<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>โรงเรียนทั้ง 426 แห่ง</h2>
                    <ul>
                        <li><a routerLink="/home-two">กลับหน้าหลัก</a></li>
                        <li><a routerLink="/services-one">ความรู้การศึกษา</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ภาคเหนือ -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคเหนือ</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">

                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school1">
                        <div class="column-school">
                            <ol>
                                <li>โรงสีข้าวพระราชทาน น่าน</li>
                                <li>โรงเรียนเทศบาล 8 บ้านใหม่ เชียงราย</li>
                                <li>วิทยาลัยชุมชนน่าน น่าน</li>
                                <li>โรงเรียนบ้านดอนแท่น น่าน</li>
                                <li>โรงเรียนบ้านห้วยยาง อุตรดิตถ์</li>
                                <li>โรงเรียนบ้านห้วยคอม อุตรดิตถ์ </li>
                                <li>โรงเรียนบ้านบ่อเบี้ย อุตรดิตถ์ </li>
                                <li>โรงเรียนบ้านน้ําหอม ตาก </li>
                                <li>โรงเรียนบ้านสามหมื่น ตาก</li>
                                <li>โรงเรียนบ้านแม่ตื่น ตาก</li>
                                <li>โรงเรียนบ้านกล้อทอ ตาก</li>
                                <li>โรงเรียนบ้านอมพาย แม่ฮ่องสอน</li>
                                <li>โรงเรียนศิษย์เก่าพยาบาลศิริราชบ้านดง แม่ฮ่องสอน</li>
                                <li>โรงเรียนทาเหนือวิทยา เชียงใหม่</li>
                                <li>โรงเรียนเจ้าพ่อหลวงอุปถัมภ์ ๙เชียงใหม่</li>
                                <li>โรงเรียนบ้านห้วยตอง เชียงใหม่</li>
                                <li>โรงเรียนบ้านปางอุ๋ง เชียงใหม่</li>
                                <li>โรงเรียนบ้านขุนแปะ เชียงใหม่</li>
                                <li>โรงเรียนบ้านสระ พะเยา</li>
                                <li>โรงเรียนบ้านโม่งหลวง เชียงใหม่</li>
                                <li>โรงเรียนบ้านป่าลัน เชียงราย</li>
                                <li>โรงเรียนบ้านเทอดไทย เชียงราย</li>
                                <li>โรงเรียนบ้านแซววิทยาคม เชียงราย</li>
                                <li>โรงเรียนปงรัชดาภิเษก พะเยา</li>
                                <li>โรงเรียนบ้านสันติสุข พะเยา</li>
                            </ol>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>26.โรงเรียนมัธยมกัลยาณิวัฒนาเฉลิมพระเกียรติ เชียงใหม่</li>
                                <li>27.โรงเรียนทุ่งช้าง น่าน</li>
                                <li>28.โรงเรียนไตรเขตประชาสามัคคี - รัชมังคลาภิเษก น่าน</li>
                                <li>29.โรงเรียนบ้านหลวง น่าน</li>
                                <li>30. โรงเรียนประกิตเวชศักดิ์ น่าน
                                <li>31. โรงเรียนบ้านนายาง น่าน</li>
                                <li>32. โรงเรียนบ้านแม่แรม แพร่</li>
                                <li>33. โรงเรียนบ้านห้วยฮ่อม แพร่</li>
                                <li>34. โรงเรียนบ้านวังเลียง แพร่</li>
                                <li>35. โรงเรียนสรอยเสรีวิทยา แพร่</li>
                                <li>36. โรงเรียนบ้านหนองเขียว เชียงใหม่</li>
                                <li>37. โรงเรียนน้ำปาดชนูปถัมภ์ อุตรดิตภ์</li>
                                <li>38. โรงเรียนบ้านปงแม่ลอบ ลำพูน</li>
                                <li>39. โรงเรียนบ้านปงแม่ลอบ สาขาห้วยฮ่อม ลำพูน</li>
                                <li>40. โรงเรียนบ้านใหม่เจริญธรรม พิษณุโลก</li>
                                <li>41.ศกร.ตชด.บ้านปลายสองแง่ แม่ฮ่องสอน</li>
                                <li>42.ศกร.ตชด.บ้านลุกข้าวหลาม แม่ฮ่องสอน</li>
                                <li>43. โรงเรียนทุติยะโพธิ์อนุสรณ์ เชียงใหม่</li>
                                <li>44. โรงเรียนเบญจมะ1 เชียงใหม่</li>
                                <li>45. โรงเรียนบ้านโตเเฮ แม่ฮ่องสอน</li>
                                <li>46. โรงเรียนบ้านโกแประ แม่ฮ่องสอน</li>
                                <li>47. โรงเรียนชมรมอนุรักษ์พุทธศิลป์ฯ2 แม่ฮ่องสอน</li>
                                <li>48. โรงเรียนบ้านจ่อปร่าคี แม่ฮ่องสอน</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>49. โรงเรียนสบปราบพิทยาคม ลำปาง</li>
                                <li>50. โรงเรียนเทศบาลท่าอิฐ อุตรดิตถ์</li>
                                <li>51. ศกร.ตชด.บ้านแม่หลองใต้ เชียงใหม่</li>
                                <li>52. ศกร.ตชด.บ้านแม่ขอ เชียงใหม่</li>
                                <li>53. ศกร.ตชด.บ้านแม่มุใน เชียงใหม่</li>
                                <li>54. โรงเรียนรางวัลอินทิรา คานธี เชียงใหม่</li>
                                <li>55. โรงเรียนเจ้าพ่อหลวงอุปถัมภ์2 เชียงใหม่</li>
                                <li>56. โรงเรียนท่านผู้หญิงประไพ เชียงใหม่</li>
                                <li>57. โรงเรียน้านขุนแตะ เชียงใหม่</li>
                                <li>58. โรงเรียนบ้านป่าเลา ลำพูน</li>
                                <li>59. โรงเรียนบ้านล้องเครือกวาว ลำพูน</li>
                                <li>60. โรงเรียนวัดสันต้นธง ลำพูน</li>
                                <li>61. โรงเรียนบ้านศรีสุพรรณ ลำพูน</li>
                                <li>62. โรงเรียนบ้านดอยติ้ว น่าน</li>
                                <li>63. โรงเรียนบ้านม่วงใหม่ น่าน</li>
                                <li>64. โรงเรียนบ้านสบเป็ด น่าน</li>
                                <li>65. ศกร.ตชด.บ้านห้วยปุ้ม พะเยา</li>
                                <li>66. โรงเรียนเพื่อชีวิตเชียงใหม่ เชียงใหม่</li>
                                <li>67. โรงเรียน ศกร.ตชด.บ้านห้วยลู่ น่าน</li>
                                <li>68. โรงเรียน ศกร.ตชด.มู นามะอื้น เชียงใหม่</li>
                                <li>69. โรงเรียนชุมชนธรรมชาติ น่าน</li>
                                <li>70. โรงเรียนบ้านขุนแปะ เชียงใหม่</li>
                                <li>71. โรงเรียนบ้านห้วยตอง เชียงใหม่</li>
                                <li>72. โรงเรียนบ้านปางอุ๋ง เชียงใหม่</li>
                                <li>73. โรงเรียนบ้านโม่งหลวง เชียงใหม่</li>
                                <li>74. โรงเรียนทางเหนือวิทยา เชียงใหม่</li>
                                <li>75. โรงเรียนบ้านอมพาย แม่ฮ่องสอน</li>
                                <li>76. โรงเรียนบ้านแซววิทยาคม เชียงราย</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north5.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/north/north6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ภาคตะวันออกเฉียงเหนือ -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคตะวันออกเฉียงเหนือ</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">
                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school">
                        <div class="column-school">
                            <ol>
                                <li>โรงพยาบาลส่งเสริมสุขภาพตำบลชุมชนแก้งยาว ชัยภูมิ</li>
                                <li>ชุมชนบ้านโนนคูณ ชัยภูมิ</li>
                                <li>ชุมชนบ้านห้วยบง ชัยภูมิ</li>
                                <li>ชุมชนบ้านมะเกลือ ชัยภูมิ</li>
                                <li>โรงเรียนบ้านดอนบาก อุดรธานี</li>
                                <li>โรงเรียนบ้านดอนกลอยดอนอุดม อุดรธานี</li>
                                <li>โรงเรียนบ้านหนองบ่อ อุดรธานี</li>
                                <li>โรงเรียนบ้านขุนหาญ ศรีสะเกษ</li>
                                <li>โรงเรียนบ้านบึงกระโพธิ์ ศรีสะเกษ</li>
                                <li>โรงเรียนบ้านดอนข่า ศรีสะเกษ</li>
                                <li>โรงเรียนบ้านจันลม ศรีสะเกษ</li>
                                <li>โรงเรียนบ้านวังมน หนองบัวลำภู</li>
                                <li>โรงเรียนบ้านดงบาก หนองบัวลำภู</li>
                                <li>โรงเรียนบ้านกุดผึ้ง หนองบัวลำภู</li>
                                <li>โรงเรียนบ้านนาดีค่ายสว่างวิทยา หนองบัวลำภู</li>
                                <li>โรงเรียนบ้านโพนสวาง สกลนคร</li>
                                <li>โรงเรียนบ้านบะนกทา สกลนคร</li>
                                <li>โรงเรียนบ้านหนองบัวสิม สกลนคร</li>
                                <li>โรงเรียนบ้านถ้ำเต่า สกลนคร</li>
                                <li>โรงเรียนนาจานกล้วยน้อย สกลนคร</li>
                                <li>โรงเรียนบ้านแท่นทัพไทย บุรีรัมย์</li>
                                <li>โรงเรียนบ้านน้อยหนองหว้า บุรีรัมย์</li>
                                <li>โรงเรียนบ้านลำนางรอง บุรีรัมย์</li>
                                <li>โรงเรียนนิคมสร้างตนเอง5 บุรีรัมย์</li>
                                <li>โรงเรียนบ้านท่าลี่ อุดรธานี</li>
                            </ol>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>26. โรงเรียนบ้านหมากตูมดอนยานาง อุดรธานี</li>
                                <li>27. โรงเรียนบ้านทองอินทร์สวนมอญ อุดรธานี</li>
                                <li>28. โรงเรียนบ้านกกปลาซิวนาโด่ สกลนคร</li>
                                <li>29. โรงเรียนชุมชนบ้านหนองปลิง สกลนคร</li>
                                <li>30. โรงเรียนผดุงราษฎร์วิทยา กาฬสินธุ์</li>
                                <li>31. โรงเรียนบ้านหนองคอง มุกดาหาร</li>
                                <li>32. โรงเรียนบ้านแก้งนาง มุกดาหาร</li>
                                <li>33. โรงเรียนบ้านกกตูม มุกดาหาร</li>
                                <li>34. โรงเรียนบ้านโอทะลัน สุรินทร์</li>
                                <li>35. โรงเรียนบ้านหนองคันนา สุรินทร์</li>
                                <li>36. โรงเรียนบ้านอำปึล สุรินทร์</li>
                                <li>37. โรงเรียนบ้านโจรก สุรินทร์</li>
                                <li>38. โรงเรียนสตรีวิทยาสมาคม สุรินทร์</li>
                                <li>39. โรงเรียนบ้านโนนหนองบัว อุบลราชธานี</li>
                                <li>40. โรงเรียนบ้านกุ่ม อุบลราชธานี</li>
                                <li>41. โรงเรียนบ้านห้วยเดื่อ อุบลราชธานี</li>
                                <li>42. โรงเรียนบ้านค้อ อุบลราชธานี</li>
                                <li>43. โรงเรียนสมเด็จพระราชชนนี อุบลราชธานี</li>
                                <li>44. โรงเรียนบ้านคำบาก อุบลราชธานี</li>
                                <li>45. โรงเรียนวิทยาลัยอาชีวจุลมณีอุทุมพรพิสัย ศรีสะเกษ</li>
                                <li>46. โรงเรียนบ้านหนองบัวเรณ ศรีสะเกษ</li>
                                <li>47. โรงเรียนบ้านสิริขุนหาญ ศรีสะเกษ</li>
                                <li>48. โรงเรียนขุนหาญวิทยาสรรค์ ศรีสะเกษ</li>
                                <li>49. โรงเรียนอนุบาลอำเภอน้ำเกลี้ยง (เขิน) ศรีสะเกษ</li>
                                <li>50. โรงเรียนพินิจราษฎร์บำรุง กาฬสินธุ์</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>51. โรงเรียนกุงแก้ววิทยาคารหนองบัวลำภู
                                <li>52. โรงเรียนบ้านหนองกุงแก้วหนองบัวลำภู</li>
                                <li>53. โรงเรียนขามสะแกแสง นครราชสีมา</li>
                                <li>54. โรงเรียนโคราชพิทยาคม นครราชสีมา</li>
                                <li>55. โรงเรียนโนนไทยคุรุอุปถัมภ์ นครราชสีมา</li>
                                <li>56. โรงเรียนสตรีวิทยาสมาคม สุรินทร์</li>
                                <li>57. โรงเรียนหนองนกเขียนสามัคคี นครราชสีมา</li>
                                <li>58. โรงเรียนบ้านวังกกเดื่อ เลย</li>
                                <li>59. โรงเรียนบ้านวังเลาหัวฝาย เลย</li>
                                <li>60. โรงเรียนบ้านสวนปอ เลย</li>
                                <li>61. โรงเรียนบ้านดอนเดื่อ อุดรธานี</li>
                                <li>62. โรงเรียนบ้านนาโคก หนองคาย</li>
                                <li>63. โรงเรียนบ้านพรานอ้น มุกดาหาร</li>
                                <li>64. โรงเรียนบ้านดงวิทยาคาร นครพนม</li>
                                <li>65. โรงเรียนบ้านโพธิ์ท่าเมือง อุดรธานี</li>
                                <li>66. ศูนย์พัฒนาเด็กเล็กวัดป่าเลไลย์ บุรีรัมย์</li>
                                <li>67. โรงเรียนราชประชานุเคาระห์ 52 เลย</li>
                                <li>68. โรงเรียนบ้านวังมน หนองบัวลำภู</li>
                                <li>69. โรงเรียนบ้านผักคำภู สกลนคร</li>
                                <li>70. วัดป่าอริยวงศ์ ร้อยเอ็ด</li>
                                <li>71. โรงเรียนบ้านโพนแพง เจียรวนนท์อุทิศ 5 สกลนคร</li>
                                <li>72. โรงเรียนบ้านเชียงแหว อุดรธานี</li>
                                <li>73. โรงเรียนบ้านป่ากล้วย นครราชสีมา</li>
                                <li>74. โรงเรียนห้วยแก้ว ชัยภูมิ</li>
                                <li>75. โรงเรียนบ้านดงเย็น ขอนแก่น</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne5.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/ne/ne6.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">
                    <div class="section-title">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school">
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>51. โรงเรียนกุงแก้ววิทยาคารหนองบัวลำภู</li>
                                <li>52. โรงเรียนบ้านหนองกุงแก้วหนองบัวลำภู</li>
                                <li>53. โรงเรียนขามสะแกแสง นครราชสีมา</li>
                                <li>54. โรงเรียนโคราชพิทยาคม นครราชสีมา</li>
                                <li>55. โรงเรียนโนนไทยคุรุอุปถัมภ์ นครราชสีมา</li>
                                <li>56. โรงเรียนสตรีวิทยาสมาคม สุรินทร์</li>
                                <li>57. โรงเรียนหนองนกเขียนสามัคคี นครราชสีมา</li>
                                <li>58. โรงเรียนบ้านวังกกเดื่อ เลย</li>
                                <li>59. โรงเรียนบ้านวังเลาหัวฝาย เลย</li>
                                <li>60. โรงเรียนบ้านสวนปอ เลย</li>
                                <li>61. โรงเรียนบ้านดอนเดื่อ อุดรธานี</li>
                                <li>62. โรงเรียนบ้านนาโคก หนองคาย</li>
                                <li>63. โรงเรียนบ้านพรานอ้น มุกดาหาร</li>
                                <li>64. โรงเรียนบ้านดงวิทยาคาร นครพนม</li>
                                <li>65. โรงเรียนบ้านโพธิ์ท่าเมือง อุดรธานี</li>
                                <li>66. ศูนย์พัฒนาเด็กเล็กวัดป่าเลไลย์ บุรีรัมย์</li>
                                <li>67. โรงเรียนราชประชานุเคาระห์ 52 เลย</li>
                                <li>68. โรงเรียนบ้านวังมน หนองบัวลำภู</li>
                                <li>69. โรงเรียนบ้านผักคำภู สกลนคร</li>
                                <li>70. วัดป่าอริยวงศ์ ร้อยเอ็ด</li>
                                <li>71. โรงเรียนบ้านโพนแพง เจียรวนนท์อุทิศ 5 สกลนคร</li>
                                <li>72. โรงเรียนบ้านเชียงแหว อุดรธานี</li>
                                <li>73. โรงเรียนบ้านป่ากล้วย นครราชสีมา</li>
                                <li>74. โรงเรียนห้วยแก้ว ชัยภูมิ</li>
                                <li>75. โรงเรียนบ้านดงเย็น ขอนแก่น</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>76. โรงเรียนบ้านดอนด่านใน นครราชสีมา</li>
                                <li>77. โรงเรียนบ้านท่าช้าง นครราชสีมา</li>
                                <li>78. โรงเรียนสง่าพัฒนา นครราชสีมา</li>
                                <li>79. โรงเรียนบ้านคลองเสือ นครราชสีมา</li>
                                <li>80. โรงเรียนบ้านบุ่งเตย นครราชสีมา</li>
                                <li>81. โรงเรียนบ้านตลองดินดำ นครราชสีมา</li>
                                <li>82. โรงเรียนบ้านเทานาบอน หนองคาย</li>
                                <li>83. โรงเรียนบ้านสวนหม่อน ขอนแก่น</li>
                                <li>84. โรงเรียนโดมประดิษฐ์วิทยา อุบลราชธานี</li>
                                <li>85. โรงเรียนบ้านดอนกลอยดอนอุดม อุดรธานี</li>
                                <li>86. หอประชุมอำเภอปักธงชัย นครราชสีมา</li>
                                <li>87. โรงเรียนบ้านอีเลี่ยน อุดรธานี</li>
                                <li>88. โรงเรียนชุมชนประชาสงเคราะห์ 4 หนองคาย</li>
                                <li>89. โรงเรียนบ้านสังกะลีนาขาม หนองคาย</li>
                                <li>90. โรงเรียนห้วยไซงัว หนองคาย</li>
                                <li>91. โรงเรียนสังคมวิทยา หนองคาย</li>
                                <li>92. โรงเรียนบ้านหนอง หนองคาย</li>
                                <li>93. โรงเรียนบ้านนาโคก หนองคาย</li>
                                <li>94. โรงเรียนบ้านปากโสม หนองคาย</li>
                                <li>95. โรงเรียนบ้านแก้งใหม่ หนองคาย</li>
                                <li>96. โรงเรียนบ้านผาตั้ง หนองคาย</li>
                                <li>97. โรงเรียนอนุบาลสังคม หนองคาย</li>
                                <li>98. โรงเรียนวังม่วงพิทยาคม หนองคาย</li>
                                <li>99. โรงเรียนบ้านดงต้อง หนองคาย</li>
                                <li>100. โรงเรียนบ้านฟ้าประทาน หนองคาย</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>101. โรงเรียนนางิ้ว หนองคาย</li>
                                <li>102. โรงเรียนเทพประทาน(เทพประทับ) หนองคาย</li>
                                <li>103. โรงเรียนซำเจียง-ดงป่าเปือย หนองคาย</li>
                                <li>104. โรงเรียนบ้านม่วง /ตาดเสริม/บ้านวังมน หนองคาย</li>
                                <li>105. โรงเรียนสังคมวิทยา หนองคาย</li>
                                <li>106. โรงเรียนวังม่วงพิทยาคม หนองคาย</li>
                                <li>107. โรงเรียนชุมชนประชาสงค์เคราะห์ 4 หนองคาย</li>
                                <li>108. โรงเรียนอนุบาลสังคม หนองคาย</li>
                                <li>109. โรงเรียนบ้านปากโสม หนองคาย</li>
                                <li>110. โรงเรียนบ้านผาตั้ง หนองคาย</li>
                                <li>111. โรงเรียนบ้านดงต้อง หนองคาย</li>
                                <li>112. โรงเรียนบ้านโสมกล้า หนองคาย</li>
                                <li>113. โรงเรียนบ้านนาโคก หนองคาย</li>
                                <li>114. โรงเรียนบ้านแก้งใหม่ หนองคาย</li>
                                <li>115. โรงเรียนบ้านม่วง วังมน ตาดเสริม หนองคาย</li>
                                <li>116. โรงเรียนบ้านหนอง หนองคาย</li>
                                <li>117. โรงเรียนบ้านฟ้าประทาน หนองคาย</li>
                                <li>118. โรงเรียนบ้านหนองมน หนองบัวลำภู</li>
                                <li>119. โรงเรียนบ้านจันลม ศรีสะเกษ</li>
                                <li>120. โรงเรียนบ้านห้วยไซงัว หนองคาย</li>
                                <li>121. โรงเรียนบ้านสังกะลีนาขาม หนองคาย</li>
                                <li>122. โรงเรียนบ้านเทพประทับ หนองคาย</li>
                                <li>123. โรงเรียนบ้านนางิ้ว หนองคาย</li>
                                <li>124. โรงเรียนบ้านซำเจียง-ดงป่าเปลือย หนองคาย</li>
                                <li>125. วัดศาสนูปถัมภ์บ้านบง หนองคาย</li>
                                <li>126. โรงเรียนตำรวจตระเวนชายแดนชายแดนบ้านไทยเสรี บึงกาฬ</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ภาคตะวันออก -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคตะวันออก</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/east/east1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/east/east2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/east/east3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">

                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <ul style="list-style-type: none;">
                        <li>1. โรงเรียนบ้านเพชรบูรพา น่าน
                        <li>2. โรงเรียนเขาชะเมาวิทยา เชียงราย</li>
                        <li>3. โรงเรียนบ้านหนองใหญ่ น่าน</li>
                        <li>4. โรงเรียนบ้านทุ่งส่อหงษา น่าน</li>
                        <li>5. โรงเรียนบ้านอ่างเสือดำ อุตรดิตถ์ </li>
                        <li>6. โรงเรียนสียัดพัฒนา อุตรดิตถ์ </li>
                        <li>7. โรงเรียนบ้านหนองปลาซิว อุตรดิตถ์ </li>
                        <li>8. โรงเรียนบ้านโคกไพล ตาก </li>
                        <li>9. โรงเรียนซับม่วงวิทยา ตาก </li>
                        <li>10. โรงเรียนวังไพรวิทยาคม ตาก </li>
                        <li>11. โรงเรียนบ้านเขาดิน ตาก </li>
                        <li>12. โรงเรียนเพชรรัตนราชสุดา แม่ฮ่องสอน</li>
                        <li>13. โรงเรียนบ้านโคกกระท้อน แม่ฮ่องสอน</li>
                        <li>14. โรงเรียนบ้านคลองยาง เชียงใหม่</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ภาคตะวันตก -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคตะวันตก</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">

                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school">
                        <div class="column-school">
                            <ol>
                                <li>โรงเรียนวัดสมุทรโคดม เพชรบุรี</li>
                                <li>โรงเรียนนวัดคงคาราม ราชบุรี</li>
                                <li>ร.ร.ต.ช.ด.บ้านแม่น้ำน้อย กาญจนบุรี</li>
                                <li>โรงเรียนบ้านโข๊ะทะ (อุ้มผาง) ตาก</li>
                                <li>โรงเรียนโพธาวัฒนาเสนี ราชบุรี</li>
                                <li>โรงเรียนบ้านดงเสลา กาญจนบุรี</li>
                                <li>โรงเรียนศรีสวัสดิ์พิทยาคม กาญจนบุรี</li>
                                <li>โรงเรียนหนองปรือพิทยาคม กาญจนบุรี</li>
                                <li>โรงเรียนบ้านโป่งหวาย กาญจนบุรี</li>
                                <li>โรงเรียนวัดถ้ำองจุ กาญจนบุรี</li>
                                <li>โรงเรียนบ้านหนองสาหร่าย กาญจนบุรี</li>
                                <li>โรงเรียนบ้านห้วยหวาย กาญจนบุรี</li>
                            </ol>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>13. โรงเรียนบ้านกองม่องทะ กาญจนบุรี</li>
                                <li>14. โรงเรียนบ้านหินตั้ง กาญจนบุรี</li>
                                <li>15. โรงเรียนบ้านไร่ กาญจนบุรี</li>
                                <li>16. โรงเรียนบ้านป่าเด็ง เพชรบุรี</li>
                                <li>17. โรงเรียนบ้านห้วยไคร้ ประจวบคีรีขันธ์</li>
                                <li>18. โรงเรียนวไลย ประจวบคีรีขันธ์</li>
                                <li>19. โรงเรียนอานันท์ ประจวบคีรีขันธ์</li>
                                <li>20. โรงเรียนบ้านปิล๊อกคี่ กาญจนบุรี</li>
                                <li>21. โรงเรียนบ้านตะโกล่าง ราชบุรี</li>
                                <li>22. โรงเรียนบ้านโป่งกระทิงบน ราชบุรี</li>
                                <li>23. โรงเรียนรุจิรพัฒน์ ราชบุรี</li>
                                <li>24. โรงเรียนห้วยทรายประชาสรรค์ เพชรบุรี</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>25. โรงเรียนบ้านด่านโง เพชรบุรี</li>
                                <li>26. โรงเรียนบ้านพุพลู เพชรบุรี</li>
                                <li>27. โรงเรียนบ้านดอนขุนห้วย เพชรบุรี</li>
                                <li>28. โรงเรียนบ้านหนองปืนแตก เพชรบุรี</li>
                                <li>29. โรงเรียนบ้านแม่คะเมย เพชรบุรี</li>
                                <li>30. โรงเรียนบ้านท่าตะคร้อมิตรภาพ ที่ 192 เพชรบุรี</li>
                                <li>31. โรงเรียนบ้านหุบกะพง เพชรบุรี</li>
                                <li>32. โรงเรียนสมาคมป่าไม้แห่งประเทศไทยอุทิศ กาญจนบุรี</li>
                                <li>33. โรงเรียนป่าเด็งวิทยา เพชรบุรี</li>
                                <li>34. โรงเรียนวัดพระพุทธบาทเขาลูกช้าง เพชรบุรี</li>
                                <li>35. โรงเรียนวัดหนองมะกอก เพชรบุรี</li>
                                <li>36. โรงเรียนบ้านหนองเขื่อน เพชรบุรี</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west5.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/west/west6.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="section-title">
                <div class="bar"></div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="row-school">
                    <div class="column-school">
                        <ul style="list-style-type: none;">
                            <li>37. โรงเรียนห้วยทรายประชาสรรค์ เพชรบุรี</li>
                            <li>38. โรงเรียนพรหมานุสรณ์ เพชรบุรี</li>
                            <li>39. โรงเรียนราชประชานุเคราะห์ 55 ตาก</li>
                            <li>40. โรงเรียนตำรวจตระเวนชายแดนบ้านท่าวังหิน ประจวบคีรีขันธ์</li>
                            <li>41. ศกร.ตชด.มูเซอหลังเมือง ตาก</li>
                            <li>42. ร.ร.ตชด.บ้านเเพรกตะคร้อ ประจวบคีรีขันธ์</li>
                            <li>43. โรงเรียนบ้านห้วยผาก ราชบุรี</li>
                            <li>44. ร.ร.ตชด.นเรศวรบ้านห้วยโสก เพชรบุรี</li>
                            <li>45. โรงเรียนอนุบาลหัวหินบ้านหนองขอน ประจวบคีรีขันธ์</li>
                            <li>46. ศกร.ตชด.ห้วยสลุง ตาก</li>
                            <li>47. โรงเรียนมรว.เฉลิมลักษณ์ จันทรเสน ตาก</li>
                            <li>48. โรงเรียนวัดพระพุทธบาทเขาลูกช้าง เพชรบุรี</li>
                            <li>49. โรงเรียนบ้านห้วยเสือ กาญจนบุรี</li>
                            <li>50. โรงเรียนบ้านทุ่งเสือโทน กาญจนบุรี</li>
                            <li>51. โรงเรียนตำรวจตระเวนชายแดนบ้านโป่งลึก เพชรบุรี</li>
                            <li>52. โรงเรียนวัดปรังกาสี กาญจนบุรี</li>
                            <li>53. โรงเรียนบ้านบึงวิทยา กาญจนบุรี</li>
                            <li>54. โรงเรียนบ้านวังตะเคียน กาญจนบุรี</li>
                        </ul>
                    </div>
                    <div class="column-school">
                        <ul style="list-style-type: none;">

                            <li>55. โรงเรียนวัดอินทาราม กาญจนบุรี</li>
                            <li>56. โรงเรียนเทศบาล1 บ้านตาลเจ็ดยอด ประจวบคีรีขันธ์</li>
                            <li>57. ศูนย์วิจัยมาลาเรียโซโกล ตาก</li>
                            <li>58. โรงเรียน.บ้านแม่อมกิสาขาบ้านปางทอง ตาก</li>
                            <li>59. โรงเรียน.บ้านแม่อมกิสาขาบ้านปอเคลอะเด ตาก</li>
                            <li>60. โรงเรียน.บ้านแม่อมกิสาขาบ้านแม่สะเปา ตาก</li>
                            <li>61. โรงเรียนอนุบาลหัวหิน(บ้านหนองขอน) ประจวบคีรีขันธ์</li>
                            <li>62. โรงเรียนตำรวจตระเวนชายแดนบ้านต้นมะม่วง กาญจนบุรี</li>
                            <li>63. โรงเรียนตำรวจตระเวนชายแดนนเรศวรป่าละอู ประจวบคีรีขันธ์</li>
                            <li>64. โรงเรียนตำรวจตระเวนชายแดนนเรศวรบ้านห้วยโสก เพชรบุรี</li>
                            <li>65. โรงเรียนตำรวจตระเวนชายแดนบ้านแพรกตะคร้อ ประจวบคีรีขันธ์</li>
                            <li>66. โรงเรียนตำรวจตระเวนชายแดนบ้านคีรีล้อม ประจวบคีรีขันธ์</li>
                            <li>67. โรงเรียนเพียงหลวง ๓ (สาขาพระธาตุโบอ่อง) กาญจนบุรี</li>
                        </ul>
                    </div>
                    <div class="column-school">
                        <ul style="list-style-type: none;">

                            <li>68. โรงเรียนตำรวจตระเวนชายแดนบ้านป่าหมาก ประจวบคีรีขันธ์</li>
                            <li>69. โรงเรียนตำรวจตระเวนชายแดนบ้านคลองน้อย ประจวบคีรีขันธ์</li>
                            <li>70. โรงเรียนตำรวจตระเวนชายแดนบ้านย่านซื่อ ประจวบคีรีขันธ์</li>
                            <li>71. โรงเรียนตำรวจตระเวนชายแดนอินทรีอาสา(บ้านปาเกอะญอ) เพชรบุรี</li>
                            <li>72. โรงเรียนตำรวจตระเวนชายแดนบ้านท่าวังหิน ประจวบคีรีขันธ์</li>
                            <li>73. โรงเรียนตำรวจตระเวนชายแดนนเรศวรป่าละอู ประจวบคีรีขันธ์</li>
                            <li>74. โรงเรียนชลประทานปราณบุรี สาขาวังปลา ประจวบคีรีขันธ์</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ภาคกลาง -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคกลาง</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">

                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school">
                        <div class="column-school">
                            <ol>
                                <li>โรงพยาบาลองค์รักษ์ นครนายก</li>
                                <li>วัดชลประทานรังสฤษดิ์ นนทบุรี</li>
                                <li>โรงเรียนบ้านหนองสรวง สุพรรณบุรี</li>
                                <li>โรงเรียนวัดปู่เจ้า สุพรรณบุรี</li>
                                <li>วัดธรรมมงคล เถาบุญญนนท์วิหาร กรุงเทพมหานคร</li>
                                <li>โรงเรียนวัดท่าอิฐ สิงห์บุรี</li>
                                <li>ศูนย์หนังสือวัดชลประทานรังสฤษดิ์ นนทบุรี</li>
                                <li>โรงเรียนวัดบัวหลวง ปทุมธานี</li>
                                <li>โรงเรียนบ้านใหม่คลองอังวะ อุทัยธานี</li>
                                <li>โรงเรียนบ้านอีมาดอีทราย อุทัยธานี</li>
                                <li>โรงเรียนห้วยขาแข้งวิทยาคม อุทัยธานี</li>
                                <li>โรงเรียนบ้านสมอทอง อุทัยธานี</li>
                                <li>โรงเรียนพระหฤทัยคอนแวนต์ กรุงเทพมหานคร</li>
                                <li>โรงเรียนบ้านร่มเกล้า พิษณุโลก</li>
                                <li>โรงเรียนบ้านน้ำจวง พิษณุโลก </li>
                            </ol>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>16. โรงเรียนบ้านสันหีบ สุโขทัย</li>
                                <li>17. โรงเรียนบ้านโซกม่วง สุโขทัย</li>
                                <li>18. โรงเรียนบ้านหนองแดน กําแพงเพชร</li>
                                <li>19. โรงเรียนบ้านสุขสําราญ กําแพงเพชร</li>
                                <li>20. โรงเรียนพิไกรวิทยา กําแพงเพชร</li>
                                <li>21. สมาคมแบตมินตันแห่งประเทศไทย กรุงเทพมหานคร</li>
                                <li>22. โรงเรียนบ้านห้วยป่าปก อุทัยธานี</li>
                                <li>23. โรงเรียนราชประชานุเคราะห์33 ลพบุรี</li>
                                <li>24. โรงเรียนลำสนธิ ลพบุรี</li>
                                <li>25. โรงเรียนวัดสง่างาม พระนครศรีอยุธยา</li>
                                <li>26. โรงเรียนบ้านคูเมือง สิงห์บุรี</li>
                                <li>27. โรงเรียนท่าวุ้งวิทยาคาร ลพบุรี</li>
                                <li>28. โรงเรียนยางรากวิทยา ลพบุรี</li>
                                <li>29. โรงเรียนบ้านพุน้ำร้อน สุพรรณบุรี</li>
                                <li>30. โรงเรียนบ้านละว้าวังควาย สุพรรณบุรี</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>31. โรงเรียนวัดคอกช้าง สุพรรณบุรี</li>
                                <li>32. โรงเรียนบ้านกล้วย สุพรรณบุรี</li>
                                <li>33. โรงเรียนบ้านห้วยหินดำ สุพรรณบุรี</li>
                                <li>34. โรงเรียนบัวปากท่าวิทยา นครปฐม</li>
                                <li>35. โรงเรียนบ่อกรุวิทยา สุพรรณบุรี</li>
                                <li>36. โรงเรียนวัดบ้านพร้าวใน ปทุมธานี</li>
                                <li>37. โรงเรียนมาลาอีสงเคราะห์ พระนครศรีอยุธยา</li>
                                <li>38. โรงเรียนวัดหนองไม้ซุง พระนครศรีอยุธยา</li>
                                <li>39. วัดโคธาราม สมุทรปราการ</li>
                                <li>40. โรงเรียนบ้านเขาขวาง ลพบุรี</li>
                                <li>41. โรงเรียนบ้านลำโป่งเพชร ลพบุรี</li>
                                <li>42. โรงเรียนบ้านโกรกรกฟ้า ลพบุรี</li>
                                <li>43. โรงเรียนชุมชนบ้านแก่งเสือเต้น ลพบุรี</li>
                                <li>44. โรงเรียนราชประชานุเคราะห์ 23 พิษณุโลก</li>
                                <li>45. โรงเรียนบางระจันวิทยา สิงห์บุรี</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen5.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/cen6.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="section-title">
                <div class="bar"></div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <div class="row-school">
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>46. โรงเรียนราชประชานุเคราะห์ 46 ชัยนาท </li>
                                <li>47. ฑัณฑสถานหญิงธนบุรี กรุงเทพมหานคร</li>
                                <li>48. โรงเรียนบ้านลานไผ่ กำแพงเพชร</li>
                                <li>49. โรงเรียนบึงเขาย้อย ปทุมธานี</li>
                                <li>50. โรงเรียนวัดจุฬาจินดาราม ปทุมธานี</li>
                                <li>51. โรงเรียนวังม่วงวิทยาคม สระบุรี</li>
                                <li>52. วัดราชบพิธสถิตมหาสีมาราม กรุงเทพมหานคร</li>
                                <li>53. โรงเรียนวัดตาลเอน อยุธยา</li>
                                <li>54. โรงเรียนวัดบางกระสอบ สมุทรปราการ</li>
                                <li>55. โรงเรียนวัดเขารูปช้าง พิจิตร</li>
                                <li>56. โรงเรียนบ้านโป่งเก้ง สระบุรี</li>
                                <li>57. ศูนย์บริการสาธารณะสุข 68 กรุงเทพมหานคร</li>
                                <li>58. วัดพระราม ๙ กรุงเทพมหานคร</li>
                                <li>59. โรงเรียนวัดเขาฝา นครสวรรค์</li>
                                <li>60. โรงเรียนวัดงิ้วราย นครปฐม</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>61. ศูนย์พัฒนาเด็กเล็กคุยบ้านโอง กำแพงเพชร</li>
                                <li>62 . สำนักงานคณะกรรมการ ปปช. นนทบุรี</li>
                                <li>63. วัดราชบพิธ กรุงเทพมหานคร</li>
                                <li>64. โรงเรียนวัดทางหลวง(เทพราษฎร์รังสรรค์) อยุธยา</li>
                                <li>65. โรงเรียนวัดรางบัวทอง สุพรรณบุรี</li>
                                <li>66. โรงเรียนบ้านซับเปิบ เพชรบูรณ์</li>
                                <li>67. โรงเรียนหนองไผ่ เพชรบูรณ์</li>
                                <li>68. ศูนย์หนังสือสถาบันพระปกเกล้า กรุงเทพมหานคร</li>
                                <li>69. โรงเรียนวัดบางสาม สุพรรณบุรี</li>
                                <li>70. โรงเรียนวัดสำเภาทอง สุพรรณบุรี</li>
                                <li>71. โรงเรียนซับม่วงวิทยา สระแก้ว</li>
                                <li>72. โรงเรียนชุมชนบ้านแก่งเสือเต้น ลพบุรี</li>
                                <li>73. อำเภออรัญประเทศ สระแก้ว</li>
                                <li>74. วัดป้อมใหญ่ อยุธยา</li>
                                <li>75. โรงเรียนบ้านห้วยรั้ว นครสวรรค์</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>76. โรงเรียนวัดทางหลวง (เทพราษฎร์รังสรรค์) พระนครศรีอยุธยา</li>
                                <li>77. ศูนย์ต่อสู้ป้องกันภัยทางอากาศกองทัพบกที่1 พระนครศรีอยุธยา</li>
                                <li>78. ศูนย์พัฒนาเด็กเล็กบ้านนาป่าแดง กำแพงเพชร</li>
                                <li>79. ศูนย์พัฒนาเด็กเล็กบ้านคุยป้อม กำแพงเพชร</li>
                                <li>80. ศูนย์พัฒนาเด็กเล็กบ้านคุยแขวน กำแพงเพชร</li>
                                <li>81. ศูนย์พัฒนาเด็กเล็กถ้ำกระต่ายทอง กำแพงเพชร</li>
                                <li>82. โรงเรียนวัดจุฬามณี (ชุณหะจันทนประชาสรรค์) พระนครศรีอยุธยา</li>
                                <li>83. โรงเรียนวัดคลองเจ้า (แช่มชื่นราษฎร์บำรุง) นนทบุรี</li>
                                <li>84. บ้านบึงสีกันหลักสี่ กทม กรุงเทพมหานคร</li>
                                <li>85. ศูนย์การศึกษาพิเศษประจำจังหวัดสมุทรสาคร สมุทรสาคร</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ภาคใต้ -->
<section class="project-details-area ptb-100">
    <div class="container">
        <h3>ภาคใต้</h3>
        <div class="creative-content">
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/sou1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/sou2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai-426">
                    <div class="projects-image">
                        <img src="assets/images/cen/sou3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 1rem;" class="projects-details-desc">

                    <div class="creative-content">
                        <div class="bar"></div>
                    </div>
                    <div class="row-school">
                        <div class="column-school">
                            <ol>
                                <li>โรงเรียนประชาอุทิศบ้านโคกม่วง ปัตตานี</li>
                                <li>โรงเรียนบ้านหัวคลอง ปัตตานี</li>
                                <li>โรงเรียนวัดมะกรูด ปัตตานี</li>
                                <li>โรงเรียนสมเด็จหลวงพ่อทวดวัดช้างไห้ ปัตตานี</li>
                                <li>โรงเรียนวัดสุวรรณากร ปัตตานี</li>
                                <li>โรงเรียนวัดบันลือคชาวาส ปัตตานี</li>
                                <li>โรงเรียนวัดเกาะหวาย ปัตตานี</li>
                                <li>โรงเรียนวัฒนธรรมอิสลามปอเนาะพ่อมิ่งปัตตานี</li>
                                <li>โรงเรียนวัดศรีสุนทร ภูเก็ต</li>
                                <li>โรงเรียนวัดลัฎฐวนาราม ภูเก็ต</li>
                                <li>วัดบ้านสวน พัทลุง</li>
                                <li>โรงเรียนคุรุชนพัฒนา ยะลา</li>
                                <li>โรงเรียนบ้านบาละ ยะลา</li>
                                <li>โรงเรียนบ้านสาเมาะ นราธิวาส</li>
                                <li>โรงเรียนบ้านบูเกะตา นราธิวาส</li>
                                <li>โรงเรียนนิคมพัฒนา ๑๐ นราธิวาส</li>
                                <li>โรงเรียนบ้านมูโน๊ะ นราธิวาส</li>
                            </ol>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>18. โรงเรียนบ้านวังกว้าง ปัตตานี</li>
                                <li>19. โรงเรียบ้านตะโละไกรทอง ปัตตานี</li>
                                <li>20. โรงเรียนบ้านเกาะพลวย สุราษธานี</li>
                                <li>21. โรงเรียนราชประชานุเคราะห์38 ระนอง</li>
                                <li>22. โรงเรียนบ้านป่าโอน สงขลา </li>
                                <li>23. โรงเรียนบ้านกระอาน สงขลา</li>
                                <li>24. โรงเรียนบ้านคูนายสังข์ สงขลา </li>
                                <li>25. โรงเรียนบ้านคลองกูด สุราษธานี</li>
                                <li>26. โรงเรียนควนสามัคคี ชุมพร</li>
                                <li>27. โรงเรียนบ้านห้วยเหมือง ชุมพร</li>
                                <li>28. โรงเรียนสิริราษฎร์ ชุมพร</li>
                                <li>29. โรงเรียนบ้านสวนเพชร ชุมพร</li>
                                <li>30. โรงเรียนกอเตย สุราษฎร์ธานี</li>
                                <li>31. โรงเรียนยางโพรง สุราษฎร์ธานี</li>
                                <li>32. โรงเรียนบ้านคลองวาย สุราษฎร์ธานี</li>
                                <li>33. โรงเรียนเทคนิคมีนบุรีอนุสรณ์ สุราษฏร์ธานี</li>
                                <li>34. โรงเรียนบ้านตะแบกงาม ชุมพร</li>
                            </ul>
                        </div>
                        <div class="column-school">
                            <ul style="list-style-type: none;">
                                <li>35. โรงเรียนบ้านห้วยตง นครศรีธรรมราช</li>
                                <li>36. โรงเรียนบ้านเขาวัง นครศรีธรรมราช</li>
                                <li>37. วัดเปรมศรัทธา สงขลา</li>
                                <li>38. โรงเรียนบ้านหาดทรายเพ สงขลา</li>
                                <li>39. โรงเรียนบ้านชายควน สงขลา</li>
                                <li>40. โรงเรียนพลเอกนวล-คุณหญิงบานชื่น สงขลา</li>
                                <li>41. โรงเรียนบ้านมูโนะ นราธิวาส</li>
                                <li>42. โรงเรียนบ้านสาเมาะ นราธิวาส</li>
                                <li>43. โรงเรียนวัดเมืองใหม่ ภูเก็ต</li>
                                <li>44. โรงเรียนวัดอินทนิน พังงา</li>
                                <li>45. ศูนย์ฝึกและอบรมเด็กและเยาวชนเขต 8 สุราษฎร์ธานี</li>
                                <li>46. โรงเรียนเกาะเคี่ยม พังงา</li>
                                <li>47. โรงเรียนบ้านลำไพล สงขลา</li>
                                <li>48. โรงเรียนวัดตรีธาราราม สุราษฎร์ธานี</li>
                                <li>49. โรงเรียนบ้านแก้งนาง มุกดาหาร</li>
                                <li>50. โรงเรียนบ้านบาละ ยะลา</li>
                                <li>51. โรงเรียนบ้านบูเกะตา นราธิวาส</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
