<app-navbar-one></app-navbar-one>
<!-- การแพทย์ -->
<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>การแพทย์</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- save-thai-figt-covid -->

<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>โครงการ <span>Save Thai Fight Covid</span></h2>
            <div class="bar"></div>
        </div>
        <div class="custom-row-4-col">
            <div class="">
                <div class="custom-projects-box-stf">
                    <div class="projects-image">
                        <img src="assets/images/save-thai-fight.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดชั้นวางหนังสือในห้องสมุด</h3>

                    </div>

                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-stf">
                    <div class="projects-image">
                        <img src="assets/images/savethai/stfc2.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>หนังสือที่ได้รับการบริจาค</h3>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-stf">
                    <div class="projects-image">
                        <img src="assets/images/savethai/stfc4.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดโต๊ะ + เก้าอี้ อ่านหนังสือ</h3>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="custom-projects-box-stf">
                    <div class="projects-image">
                        <img src="assets/images/savethai/stfc3.png" alt="img">
                    </div>
                    <div class="projects-content">
                        <h3>ชุดโต๊ะ + เก้าอี้ นักเรียน</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- จุดเริ่มต้นเล็กๆสู่ก้าวที่ยิ่งใหญ่ -->
<section class="creative-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>จุดเริ่มต้นเล็กๆ <span>สู่ก้าวที่ยิ่งใหญ่</span></h3>
                    <div class="bar"></div>
                    <p>“ขอความช่วยเหลือให้เข้าไปตรวจสอบผู้ป่วยเป็นเพศชาย ​อายุประมาณ 60 ปี มีอาการแขนขาบวม ได้โทรแจ้งมูลนิธิ​ต่างๆแล้วไม่มีหน่วยงานไหนเข้าไปดู และอาจเป็นภัยต่อผู้​อื่น จึงโทรมาขอความช่วยเหลือ สถานที่ที่ผู้ป่วยอยู่ ​ซ.สามัคคี 21 ต.ท่าทราย จ.นนทบุรี ค่ะ"</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="vdo">
                    <video controls loop src="assets/images/savethai/savevdo.mp4" width="100.25%"></video>
                </div>
            </div>
            <div class="custom-row-col-3">
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/medic2/save2.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/medic2/save1.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/medic2/save4.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/punjai/mdc.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/medic2/save5.png" alt="image">
                        </div>

                    </div>
                </div>
                <div class="">
                    <div class="custom-projects-box med">
                        <div class="projects-image">
                            <img src="assets/images/mdc-2-2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- จัดหาอุปกรณ์สำคัญ -->
<section class="creative-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>จัดหาอุปกรณ์สำคัญทางการแพทย์ที่จำเป็น</h3>
                    <div class="bar"></div>
                    <h5>ต่อการใช้ในการรักษาและป้องกัน
                        ให้แก่บุคลากรทางการแพทย์ทั่วประเทศ</h5>
                        <ul class="list" style="list-style-type: none;">
                            <li><i class="flaticon-happy"></i> หน้ากาก Face Shield</li><br>
                            <li><i class="flaticon-happy"></i> แอลกอฮอล์ 75% ฆ่าเชื้อ</li><br>
                            <li><i class="flaticon-happy"></i> กล่องอะคริลิคครอบผู้ป่วย</li><br>
                            <li><i class="flaticon-happy"></i> ตู้ฆ่าเชื้อด้วยแสง UVC</li><br>
                            <li><i class="flaticon-happy"></i> Acrylic Stand</li><br>
                        </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="vdo-l">
                    <video controls loop src="assets/images/bed-mdc/bdmdvdo.mp4" width="546px" height="500px"></video>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd1.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd2.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd3.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd4.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd5.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="mdc-box">
                    <div class="projects-image">
                        <img src="assets/images/bedbed/bdmd6.png" alt="img">
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>
<!-- เตียง500 -->
<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>สนับสนุนสำหรับผู้ป่วย</h2>
            <h5>มากกว่า 500 เตียง</h5>
            <ul class="list" style="list-style-type: none;">
                <li><i class="flaticon-happy"></i> โรงพยาบาลผู้สูงอายุบางขุนเทียน</li><br>
                <li><i class="flaticon-happy"></i> โรงพยาบาลราชพิพัฒน์</li><br>
                <li><i class="flaticon-happy"></i> โรงพยาบาลสนามเอราวัณ1</li><br>
                <li><i class="flaticon-happy"></i> โรงพยาบาลพระพุทธ สระบุรี</li><br>
                <li><i class="flaticon-happy"></i> โรงพยาบาลชลประทานปราณบุรี</li><br>
            </ul>

            <div class="bar"></div>
        </div>
        <div class="row row-tablet">
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed500/bedmd1.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed-mdc/bedmd2.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-punjai tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed500/bedmd3.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-bed tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed-mdc/bedmd4.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box custom-margin tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed02/bedmd6.png" alt="img">
                    </div>

                </div>
                <div class="custom-projects-box tablet" >
                    <div class="projects-image">
                        <img src="assets/images/bed02/bedmd7.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="custom-projects-box-bed tablet">
                    <div class="projects-image">
                        <img src="assets/images/bed-mdc/bedmd5.png" alt="img">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- ตู้UVC -->
<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2> นวัตกรรมตู้ <span>UVC</span> ฆ่าเชื้อ ในอุปกรณ์การแพทย์</h2>
            <p>ได้รับความร่วมมือจาก rbs Group รพ.ผู้สูงอายุ The Senizens ทีมวิจัย จากคณะวิศกรรมศาสตร์ จฬ. ศูนย์ธาลัสชีเมียและภาควิชากุมารเวชศาสตร์ มม.<p>
            <p>UVC สำหรับใช้ในการฆ่าเชื้อโรคบริเวณหน้ากากอนามัยและอุปกรณ์ทางการแพทย์ จำนวนมากกว่า 1,500 ตู้<p>
            <div class="bar"></div>
        </div>
        <div class="row row-tablet-2">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/uvc02/uvc.png" alt="img">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/uvc01/uvc3.png" alt="image">
                    </div>

                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/uvc01/uvc4.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/uvc02/uvc2.png" alt="image">
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<!-- ลาดพร้าว -->
<section class="creative-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>ศูนย์พักคอยเพื่อการส่งต่อเขต <span>ลาดพร้าว</span></h3>
                    <div class="bar"></div>
                    <h6>เปิดศูนย์วันแรก <br><span>วันที่ 12 สิงหาคม 2564</span></h6>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-imag">
                    <video controls loop src="assets/images/lps.mp4" width="100%"></video>
                </div>

            </div>
        </div>
        <div class="row row-tablet">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp2.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp3.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp1.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp4.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp5.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp6.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp7.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/lardprao/lp8.png" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- ศูนย์ที่ทันสมัย -->
<section class="creative-section pt-100 pb-100">
    <div class="container">
        <div class="row row-tablet">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <div class="bar"></div>
                    <p>เป็นศูนย์ที่ทันสมัย มีสิ่งอำนวยความสะดวกให้กับ
                        ทั้งผู้ป่วยและบุคลากรทางการแพทย์ โดยคำนึงถึง
                        ผู้อยู่เป็นสำคัญ เพื่อการส่งต่อที่ดีที่สุดในประเทศ
                        วัดจากปัจจัยตามมาตรฐานสากล และระบบที่ใช้
                        สำหรับการจัดการภายในศูนย์ อาทิเช่น
                        ระบบ CCTV ในการรักษาความปลอยภัย ระบบ ​INTERCOM  สำหรับการติดต่อแพทย์ ระบบ​กระจายเสียง ระบบการระบายอากาศ ระบบฆ่าเชื้อ​และบำบัด ระบบ Hi Speed Internet
                        พื้นที่ Green Zone และห้องสมุด</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-imag">
                    <video controls loop src="assets/images/cctv/cvdo.mp4" width="100%"></video>
                </div>

            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c5.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 p-0">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c6.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 p-0">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c7.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 p-0">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/images/cctv/c4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


